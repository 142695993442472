.main-container {
  margin-top: 64px;
  padding-top: 24px;
}

.flex-stack {
  justify-content: space-between;
  align-items: center;
}

.fw-semibold {
  font-weight: 500 !important;
}

.text-gray-700 {
  color: #5E6278 !important;
}

.separator.separator-dashed {
  border-bottom-style: dashed;
  border-bottom-color: #E1E3EA;
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid var(--bs-border-color);
}

.location-title {  
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}