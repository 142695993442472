/* Provide sufficient contrast against white background */

.bg-dark{
  background-color: #333!important; 
  color: white!important;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.ticket{
  text-align: center;
  padding-top: 1rem;
}

.ticket .service-name{
  padding: 1rem 2rem 0; 
  text-align:left
}

.ticket .service-name h4{
  margin: 0
}

.ticket .ticket-number{
  padding: 1rem 2rem;
}

.ticket .ticket-number > .card{
  color: #333;
}

.ticket .ticket-number > .card .card-text{
  font-size: 60px
}

.ticket .row.numbers .col.middle{
  border-left: 1.5px solid #555; 
  border-right: 1.5px solid #555;
}

.ticket .row.numbers .number{
  font-size: 28px;
  white-space: nowrap;
  font-weight: bold;
}

.ticket .called > div{
  font-size: 24px;
}

.ticket .label {
  color: #8f8f8f;
  font-size: 9px;
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
}

.ticket .label::after {
  content: '';
  border-bottom: 1px solid #5a5a5a;
  width: 1.5rem;
  margin: 0 auto;
  display: block
}

.ticket .card-text {
  font-weight: bold;
}

.ticket .message{
  margin-top: 1rem;  
  padding: 1rem 2rem 0; 
  text-align:left;
}

.ticket .message .message-text{
  font-size: 13px;
}

.ticket .message .last-update{
      font-size: 10px;
      color: #8f8f8f;
      margin-top: 10px;
      text-align: right;
}